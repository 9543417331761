





































































import { MutationPayload } from 'vuex';
import { defineComponent, reactive } from '@vue/composition-api';
import Textarea from '@/components/form/textarea.vue';
import Input from '@/components/form/input.vue';
import router from '@/router';
import { getEventV1, resendRegistrationEmail } from '@/api/events';
import store from '@/store';
import isEmail from '@/utils/isEmail';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';
import { inputs, form } from './index';
import { checkUser } from '@/api/users';

export default defineComponent({
  components: {
    Textarea,
    Input,
  },
  props: {
    ticketCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loginLinkSent: false,
      loading: false,
      alreadyExists: false,
      visitorId: 0,
    };
  },
  setup(props, { emit }) {
    const { translations, contextEvent } = useContext();
    const { userLoggedIn } = useAuth();

    const state = reactive({
      event: parseInt(router.currentRoute.params.event, 10),
      inputs: inputs(),
      formTitle: '',
      formDescription: '',
      form,
    });

    const emitForm = () => {
      emit('updateBasicInfo', state.form);
    };

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        state.inputs = inputs();
        if (contextEvent.value.slug) {
          getEventV1(contextEvent.value.slug).then((response) => {
            state.formTitle = response.data.attending_form_title;
            state.formDescription = response.data.attending_form_description;
          });
        }
      }
    });
    if (contextEvent.value.slug) {
      getEventV1(contextEvent.value.slug).then((response) => {
        state.formTitle = response.data.attending_form_title;
        state.formDescription = response.data.attending_form_description;

        // If visitor form isn't published, it must not be available to users
        if (response.data.settings.attending_form_status !== 'Published') {
          router.push('/');
        }
      });
    } else {
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent' && contextEvent.value.slug) {
          getEventV1(contextEvent.value.slug).then((response) => {
            state.formTitle = response.data.attending_form_title;
            state.formDescription = response.data.attending_form_description;
            // If visitor form isn't published, it must not be available to users
            if (response.data.settings.attending_form_status !== 'Published') {
              router.push('/');
            }
          });
        }
      });
    }

    // Whenever the user types, the error is reset
    state.inputs.name.onInput = (value) => {
      if (state.inputs.name.error) {
        state.inputs.name.error = false;
        state.inputs.name.message = '';
        state.inputs.name.value = value;
      }
    };

    return {
      emitForm,
      state,
      contextEvent,
      userLoggedIn,
      translations,
    };
  },
  methods: {
    next() {
      let formInvalid = false;
      if (this.state.form.name === '') {
        this.state.inputs.name = {
          ...this.state.inputs.name,
          value: this.state.form.name as string,
          error: true,
          message: this.translations.common.emptyFieldNotAllowed,
        };
        formInvalid = true;
      }
      if (this.state.form.email === '') {
        this.state.inputs.email = {
          ...this.state.inputs.email,
          value: this.state.form.email as string,
          error: true,
          message: this.translations.common.emptyFieldNotAllowed,
        };
        formInvalid = true;
      } else if (!isEmail(this.state.form.email as string)) {
        this.state.inputs.email = {
          ...this.state.inputs.email,
          value: this.state.form.email as string,
          error: true,
          message: 'Email inválido',
        };
        formInvalid = true;
      }

      if (!formInvalid) {
        checkUser({
          email: this.state.form.email,
          event: this.contextEvent.slug,
          event_id: this.contextEvent.id,
          send_confirmation_email: false,
        })
          .then((response) => {
            this.$emit('next');
          })
          .catch((err) => {
            if (err.response.status === 404) {
              this.$emit('next');
            } else {
              this.alreadyExists = true;
              this.visitorId = err.response.data.attending.id;
            }
          });
      }
    },
    sendEmailAuth() {
      this.loading = true;
      resendRegistrationEmail({ attending_id: this.visitorId, slug: this.contextEvent.slug }).then(
        (response) => {
          this.loginLinkSent = true;
          this.loading = false;
        },
      );
    },
  },
});
