






















import { computed, defineComponent, ref } from '@vue/composition-api';
import { getEventV1 } from '@/api/events';
import useContext from '@/composition/context';

// const staging = process.env.VUE_APP_SERVER_PATH !== 'https://api.beamian.com/api';
export default defineComponent({
  setup(props, { emit }) {
    const { translations, contextEvent, contextVisitor } = useContext();
    const finalFormMessage = ref('');

    const eventHasValidDates = computed(() => {
      const currentDate = new Date();
      const eventStartDate = new Date(contextEvent.value.start_date);
      const eventEndDate = new Date(contextEvent.value.end_date);

      const eventIsFinished = currentDate >= eventEndDate;
      const eventStarted = eventStartDate <= currentDate;
      return eventStarted && !eventIsFinished;
    });

    getEventV1(contextEvent.value.slug).then((response) => {
      finalFormMessage.value = response.data.attending_final_form_message;
    });

    const submit = () => {
      emit('submit');
    };

    return {
      submit,
      contextEvent,
      contextVisitor,
      translations,
      eventHasValidDates,
      finalFormMessage,
    };
  },
});
