import {
  IInput, ITextarea, ISelect, ICheckboxes, IRadios,
} from '@/components/form/index';

import { IPopup, IModal } from '@/components';
import { IVisitor, IAttendingFieldsEntity } from '@/models/visitor';
import store from '@/store';
import { ITranslations } from '@/translations';
import { IOnboardingAuthInputs } from '@/models/auth';

export interface IOnboardingForm {
  [key: string]: string
}

export const convertBridgeTitles = (title: string) => {
  const translations: ITranslations = store.getters.getTranslations;

  if (title === 'BridgeLogo') {
    return translations.exhibitors.onboarding.convert_titles.logo;
  }
  if (title === 'BridgeProfilePic') {
    return translations.exhibitors.onboarding.convert_titles.profile_pic;
  }
  if (title === 'BridgeEmbeddedVideo') {
    return translations.exhibitors.onboarding.convert_titles.embedded;
  }
  return title;
};

export const attendingInputs: Array<IInput | IRadios | ICheckboxes | ITextarea | ISelect> = [];

export const sessionGroupInputs: Array<IRadios | ICheckboxes> = [];

export const fields: Array<IAttendingFieldsEntity> = [];

export interface IVisitorOnboardingInputs {
  name: IInput;
  email: IInput;
}

export const authForm = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
};

export const authInputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  const inputs: IOnboardingAuthInputs = {
    name: {
      id: 'visitor-onboarding-name',
      name: 'visitor_onboarding_name',
      type: 'text',
      label: translations.common.name,
      placeholder: translations.common.name_placeholder,
      value: '',
    },
    email: {
      id: 'visitor-onboarding-email',
      name: 'visitor_onboarding_email',
      type: 'text',
      label: translations.common.email,
      placeholder: translations.auth.login.email_placeholder,
      value: '',
    },
    password: {
      id: 'visitor-onboarding-password',
      name: 'visitor_onboarding_password',
      type: 'password',
      label: translations.auth.login.password,
      placeholder: translations.auth.login.password_placeholder,
      value: '',
    },
    confirmPassword: {
      id: 'visitor-onboarding-confirm-password',
      name: 'visitor_onboarding_confirm_password',
      placeholder: translations.account.settings.password_confirm_placeholder,
      type: 'password',
      label: translations.account.settings.password_confirm,
      value: '',
    },
  };
  return inputs;
};

export const form: IVisitor = {
  name: '',
  email: '',
  event: 0,
};

export const inputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  const inputs: IVisitorOnboardingInputs = {
    name: {
      id: 'visitor-onboarding-name',
      name: 'visitor_onboarding_name',
      type: 'text',
      label: translations.common.name,
      placeholder: translations.common.name_placeholder,
      value: '',
    },
    email: {
      id: 'visitor-onboarding-email',
      name: 'visitor_onboarding_email',
      type: 'text',
      label: translations.auth.login.email,
      placeholder: translations.auth.login.email_placeholder,
      value: '',
    },

  };
  return inputs;
};

export const onboardingPopup: IPopup = {
  message: '',
  type: 'danger',
};

export const stringsForName = [
  'name',
  'nombre',
  'nome',
];

export const basicInformation = [
  ...stringsForName,
  'email',
  'e-mail',
];

export const modal: IModal = {
  isActive: false,
};
