







































import { MutationPayload } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import Textarea from '@/components/form/textarea.vue';
import Radios from '@/components/form/radios.vue';
import Checkboxes from '@/components/form/checkboxes.vue';
import Select from '@/components/form/select.vue';
import Input from '@/components/form/input.vue';
import store from '@/store';
import Modal from '@/components/modal.vue';
import Cropper from '@/components/form/cropper.vue';
import Description from '@/components/form/description.vue';
import RegistrationForm from '@/partials/RegistrationForm.vue';
import useForm from '@/composition/form';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Textarea,
    Input,
    Radios,
    Checkboxes,
    Select,
    Modal,
    Cropper,
    Description,
    RegistrationForm,
  },
  setup() {
    const { contextEvent, translations } = useContext();

    const { fieldDependency, buildForm, form } = useForm();

    if (contextEvent.value.id) {
      buildForm('onboarding');
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          buildForm('onboarding');
          unsubscribe();
        }
      });
    }

    return {
      contextEvent,
      translations,
      form,
      fieldDependency,
    };
  },
  methods: {
    next() {
      const { validateForm } = useForm();

      validateForm();
      if (!this.form.invalid) {
        this.$emit('next');
        this.$emit('updateFields', this.form.fields);
        this.$emit('updateSessions', this.form.sessions);
      } else {
        this.$forceUpdate();
      }
    },
  },
});
