


































































import { MutationPayload } from 'vuex';
import { defineComponent, reactive, computed } from '@vue/composition-api';
import { uploadFile } from '@/api/files';
import { newAttendee } from '@/api/visitors';
import { IVisitor, IAttendingFieldsEntity } from '@/models/visitor';
import store from '@/store';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';
import BasicInformation from './BasicInformation.vue';
import Tickets from './Tickets.vue';
import Review from './Review.vue';
import { fields, form } from '.';
import ManagedFields from './ManagedFields.vue';
import Completed from './Completed.vue';
import EventHome from '@/views/Event/Home.vue';
import { getTicketMode } from '@/api/tickets';
import router from '@/router';
// @ is an alias to /src
export default defineComponent({
  components: { EventHome },
  data() {
    return {
      activeComponent: 0,
      ticketError: false,
      stepNames: ['Informação básica', 'Informação adicional', 'Revisão', 'Terminado!'],
      fields,
      form,
      sessions: [] as { field_type: string; value?: string }[],
      components: [BasicInformation, ManagedFields, Review, Completed],
      ticketCode: '',
    };
  },
  setup() {
    const { translations, contextEvent } = useContext();
    const { userLoggedIn } = useAuth();

    const state = reactive({});

    store.commit('setUserLogout');
    store.commit('setContextRole', 'unset');
    store.commit('setContextExhibitor', {});
    store.commit('setContextVisitor', {});

    return {
      state,
      userLoggedIn,
      contextEvent,
      translations,
    };
  },
  computed: {
    progressWidth(): number {
      return this.activeComponent === 0
        ? 0
        : 100 / (this.components.length - (this.activeComponent + 1));
    },
  },
  created() {
    const event = parseInt(this.$route.params.event, 10);
    let ticketMode = '';
    getTicketMode({ event }).then((response) => {
      const ticket = response.data.results[0];
      if (ticket) {
        ticketMode = ticket.mode;
        if (ticketMode === 'vr') {
          this.components = [Tickets as any, ...this.components];
          this.stepNames = [this.translations.visitors.onboarding.tickets.title, ...this.stepNames];
        }
      }
    });
    this.stepNames = [
      this.translations.exhibitors.onboarding.step_basicInfo,
      this.translations.exhibitors.onboarding.step_additionalInfo,
      this.translations.exhibitors.onboarding.step_review,
      this.translations.exhibitors.onboarding.step_done,
    ];
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.stepNames = [
          this.translations.exhibitors.onboarding.step_basicInfo,
          this.translations.exhibitors.onboarding.step_additionalInfo,
          this.translations.exhibitors.onboarding.step_review,
          this.translations.exhibitors.onboarding.step_done,
        ];
        if (ticketMode === 'vr') {
          this.stepNames = [this.translations.visitors.onboarding.tickets.title, ...this.stepNames];
        }
      }
    });
  },
  methods: {
    next() {
      this.activeComponent += 1;
    },

    back() {
      if (this.activeComponent !== 0) {
        this.activeComponent -= 1;
      }
    },

    updateFields(fields: IAttendingFieldsEntity[]) {
      this.fields = fields;
    },

    updateBasicInfo(form: IVisitor) {
      this.form = form;
    },

    updateTicketCode(ticketCode: string) {
      this.ticketCode = ticketCode;
    },

    updateSessions(value: { field_type: string; value?: string }[]) {
      this.sessions = value;
    },

    async submit(callback?: () => void) {
      for (const [i, field] of this.fields.entries()) {
        if (field.attending_field && field.attending_field.field_type === 'file' && field.value) {
          const imageForm = new FormData();
          imageForm.append('file', field.value as string);
          // eslint-disable-next-line no-await-in-loop
          const response = await uploadFile(imageForm);

          this.fields[i] = {
            ...field,
            value: response.data.id,
          };
        }
      }

      if (this.contextEvent.slug) {
        this.form.event = this.contextEvent.id;
        newAttendee(this.contextEvent, {
          name: form.name,
          email: form.email,
          ticket_code: this.ticketCode === '' ? undefined : this.ticketCode,
          fields: this.fields,
          sessions: this.sessions.filter((session) => !!session.value),
        })
          .then((response) => {
            if (!response.data) {
              router.push({
                path: router.currentRoute.path,
                query: { error: '' },
              });
              router.push({
                path: router.currentRoute.path,
                query: { error: this.translations.visitors.onboarding.tickets.ticket_code_invalid },
              });
              this.activeComponent = 0;
            } else {
              this.activeComponent += 1;
            }
            // sendLoginLink({
            //   event: contextEvent.value.id,
            //   email: state.form.email,
            // })
            //   .then(() => {
            //     state.activeComponent += 1;
            //   })
            //   .catch((err) => {
            //     if (callback) {
            //       callback();
            //     }
            //     store.commit('addPopup', {
            //       type: 'danger',
            //       message: err.message,
            //       autohide: true,
            //     });
            //   });
          })
          .catch((err) => {
            if (callback) {
              callback();
            }
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.message,
                type: 'danger',
                autohide: true,
              });
            }
          });
      }
    },
  },
});
