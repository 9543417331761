


















































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { ParticipatingFieldsEntity } from '@/models/brand';
import { IExhibitor } from '@/models/exhibitor';
import useContext from '@/composition/context';
import { convertBridgeTitles } from '.';
import router from '@/router';

// @ is an alias to /src
export default defineComponent({
  props: {
    fields: {
      type: Array as PropType<ParticipatingFieldsEntity[]>,
      required: true,
    },
    ticketCode: {
      type: String,
      required: true,
    },
    form: {
      type: Object as PropType<IExhibitor>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const { translations } = useContext();
    const submit = () => {
      loading.value = true;
      emit('submit', () => {
        // If the submition fails and the component isn't changed to "Completed" we still want the loading to stop
        loading.value = false;
      });
    };
    return {
      URL: window.URL,
      loading,
      convertBridgeTitles,
      submit,
      translations,
    };
  },
  watch: {
    $route() {
      const { error } = router.currentRoute.query;
      if (error && typeof error === 'string') {
        this.loading = false;
      }
    },
  },
});
