





























import { defineComponent, PropType } from '@vue/composition-api';
import useContext from '@/composition/context';
import { IAvailableTicket } from '@/models/ticket';
import Input from '@/components/form/input.vue';
import { IInput } from '@/components/form';
import router from '@/router';
import { ParticipatingFieldsEntity } from '@/models/brand';
import store from '@/store';

// const staging = process.env.VUE_APP_SERVER_PATH !== 'https://api.beamian.com/api';
export default defineComponent({
  components: {
    Input,
  },
  props: {
    fields: {
      type: Array as PropType<ParticipatingFieldsEntity[]>,
      required: true,
    },
  },
  data() {
    return {
      ticketsAvailable: [] as IAvailableTicket[],
      step: 0,
      ticketCodeInput: <IInput>{
        id: 'ticket-code-input',
        name: 'ticket_code_input',
        label: 'Ticket code',
        placeholder: 'Insert your ticket code',
        value: '',
      },
      ticketCode: '',
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
    };
  },
  setup(props, { emit }) {
    const { translations, contextEvent, contextVisitor } = useContext();

    return {
      contextEvent,
      contextVisitor,
      translations,
    };
  },

  created() {
    const ticketCode = router.currentRoute.query.ticket_code as string;
    if (ticketCode) {
      this.ticketCodeInput = {
        ...this.ticketCodeInput,
        value: ticketCode,
      };
      this.ticketCode = ticketCode;
      this.$emit('updateTicketCode', this.ticketCode);
      this.$emit('next');
    }
    store.subscribe((mutation) => {
      if (mutation.type === 'setLanguage') {
        this.ticketCodeInput = {
          ...this.ticketCodeInput,
          value: this.ticketCode,
          label: this.translations.visitors.onboarding.tickets.ticket_code_label,
          placeholder: this.translations.visitors.onboarding.tickets.ticket_code_placeholder,
          message: this.translations.common.mandatory_field,
          onInput: (value) => {
            this.ticketCodeInput = { ...this.ticketCodeInput, value, error: false };
          },
        };
      }
    });
  },

  methods: {
    next() {
      const { error } = router.currentRoute.query;

      if (this.ticketCode === '') {
        this.ticketCodeInput = {
          ...this.ticketCodeInput,
          error: true,
        };
      } else {
        this.$emit('updateTicketCode', this.ticketCode);
        if (error && this.fields.length > 0) {
          this.$emit('next');
          this.$emit('next');
        }
        this.$emit('next');
      }
    },
  },
  watch: {
    $route() {
      const { error } = router.currentRoute.query;
      if (error && typeof error === 'string') {
        this.ticketCodeInput = {
          ...this.ticketCodeInput,
          error: true,
          message: error,
        };
      }
    },
  },
});
